import React from 'react';
import './App.css';
import Blog from './pages/Blog';
import Calendar2 from './pages/Calendar';
import Home from './pages/Home';
import { BrowserRouter,Redirect, Route, Routes, Switch } from 'react-router-dom';



const App = () => {
  return (
    <div className='container'>
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/blog/:id" element={<Blog />}/>
        <Route path="/calendar" element={<Calendar2 />}/>
    </Routes>
    </BrowserRouter>
    </div>

  );
}

export default App;

