import { request, gql } from 'graphql-request';

const graphqlAPI = "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cl94ket4154xo01t6fbbybn04/master";
export var currentblogList=[];

export const blogList = async () => {
  if(currentblogList.length > 0){
    return currentblogList;
  }
  const query = gql`
  query Posts {
    posts {
      id
      title
      excerpt
      auther {
        bio
        name
        photo {
          url
        }
      }
      categories {
        slug
        name
      }
      featuredImage {
        url
      }
      createdAt
    }
  }
  
  
  `;
  const result = await request(graphqlAPI, query);
  currentblogList =  sortData(result.posts);
  return currentblogList;
};

export const singlePost = async (id) => {
  const query = gql`
  query Posts {
    posts(where: {id: "${id}"}) {
      id
      title
      excerpt
      auther {
        bio
        name
        photo {
          url
        }
      }
      categories {
        slug
        name
      }
      featuredImage {
        url
      }
      createdAt
      content {
        html
      }
    }
  }
  
  
  
  `;

  const result = await request(graphqlAPI, query);
  let arr =  sortSingleData(result.posts);
  return arr;
};



function sortData(posts){
  let postArray = []
  for (const post of posts) {
    let cat = [];
    for (const cata of post.categories) {
      cat.push(cata.name)
    }
    let blog =   {
      id: post.id,
      title: post.title,
      category: cat,
      description:  post.excerpt,
      authorName: post.auther.name,
      authorAvatar: post.auther.photo.url,
      createdAt: post.createdAt.split("T")[0],
      cover: post.featuredImage.url,
    }
    postArray.push(blog);
  }
  return postArray;
}

function sortSingleData(posts){
  let postArray = []
  for (const post of posts) {
    let cat = [];
    for (const cata of post.categories) {
      cat.push(cata.name)
    }
    let blog =   {
      id: post.id,
      title: post.title,
      category: cat,
      description:  post.content.html,
      authorName: post.auther.name,
      authorAvatar: post.auther.photo.url,
      createdAt: post.createdAt.split("T")[0],
      cover: post.featuredImage.url,
    }
    postArray.push(blog);
    return postArray;
  }
}

