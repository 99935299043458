import React from 'react'
import "./style.css"
import { Link } from 'react-router-dom';

function go2Calendar() {
  console.log('Button clicked!');
}

function go2SupportCase() {
  console.log('Button clicked!');
}


const Header = () => (
    <header className="home-header">
      <h2>Salesforce Developer Blog</h2>
      <h1>
        <span></span> Salesforce Pal <span></span>
      </h1>
      <p>
        Tools and toys for your Salesforce needs!<br /> by your friendly neighborhood <i>Salesforce pal!!</i>
      </p>
      <p><br/>
      <Link  to='/calendar'>
        <i><button onClick={go2Calendar} style={{ marginRight: '10px' }}>Schedule a meeting</button></i>
      </Link>
      <Link cto='/calendar'>
       <i><button onClick={go2SupportCase}>Open a support case for my extension!</button></i>
       </Link>
      </p>
    </header>
)

export default Header
