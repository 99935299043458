import React, { useEffect, useState } from 'react'
import Header from "../../components/Home/Header"
import SeachBar from '../../components/Home/SearchBar'
import BlogList from '../../components/Home/BlogList'
import EmptyList from '../../components/common/EmptyList'
// import { blogList } from '../../config/data'
import { blogList,currentblogList } from '../../services';


const Home = () => {
  const [blogs,setBlogs] = useState(blogList)
  const [searchKey,setSearchKey] = useState('')

  useEffect(()=>{
    getBlogsFromServer();
  }, [])

  async function getBlogsFromServer(){
    let res = await blogList();
    setBlogs(res);
  }
  
  //search submit
  const handleSeachSubmit = (event) =>{
    event.preventDefault();
    handleSeachResults();
  }
  //search for blog posts or category
  const handleSeachResults = () =>{
    const allBlogs = currentblogList;
    const filteredBlogs = allBlogs.filter(blog=>blog.category[0].toLowerCase().includes(searchKey.toLowerCase().trim())
    );

    setBlogs(filteredBlogs);
  }

  const handleClearSearch = () =>{
    setBlogs(currentblogList);
    setSearchKey("");
  }

  return (
    <div>
      <Header />
      <SeachBar value={searchKey} 
                clearSearch={handleClearSearch}
                formSubmit={handleSeachSubmit} 
                handleSearchKey={ (e)=>setSearchKey(e.target.value)} />
      {!blogs.length ? <EmptyList />:<BlogList blogs={blogs} />}
    </div>
  )
}

export default Home
