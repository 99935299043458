import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick



const Calendar2 = () => {
    const handleDateClick = (arg) => {
        alert('date clicked: ' + arg.dateStr);
      };
      
  return (
    <FullCalendar defaultView="dayGridMonth"  dateClick={handleDateClick} plugins={[ dayGridPlugin, interactionPlugin ]} />
  );
};



export default Calendar2;
